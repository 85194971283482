import { createAction, props } from "@ngrx/store";
import { Content } from "../../../feeds/state/contents/content.model";

export const addContentToList = createAction(
    '[Review] Add Content To List',
    props<{ content: Content }>()
);

export const removeContentFromList = createAction(
    '[Review] Remove Content From List',
    props<{ contents: Array<Content> }>()
);

export const resetList = createAction(
    '[Review] Reset List'
);

export const sendNewsletter = createAction(
    '[Review] Send Newsletter'
);

export const sendNewsletterSuccess = createAction(
    '[Review] Send Newsletter Success'
);

export const sendNewsletterFail = createAction(
    '[Review] Send Newsletter Fail'
);
